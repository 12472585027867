import React, { useEffect } from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";

const DeleteProjectComponent = React.memo(
	({ isOpen, projectId, onCloseDialog, onDeleteProjectCallback }) => {
		useEffect(() => {
			async function init() {
				await Utils.fetchCredentials();
			}
			init();
		}, []);

		const deleteProjectImages = async () => {
			const s3 = new AWS.S3();
			const bucket = window.config.s3.bucketSubida;
			const prefix = `${projectId}/`;
			try {
				const listParams = {
					Bucket: bucket,
					Prefix: prefix,
				};
				const listedObjects = await s3
					.listObjectsV2(listParams)
					.promise();

				if (listedObjects.Contents.length > 0) {
					const deleteParams = {
						Bucket: bucket,
						Delete: {
							Objects: listedObjects.Contents.map(({ Key }) => ({
								Key,
							})),
							Quiet: true,
						},
					};
					await s3.deleteObjects(deleteParams).promise();
				}
			} catch (error) {
				console.error("Error deleting files:", error);
				iziToast.error({
					title: "Error",
					message: "Failed to delete existing files.",
				});
				throw error;
			}
		};

		const onDeleteProject = async () => {
			try {
				await deleteProjectImages();
				const token = localStorage.getItem(
					Globals.LOCAL_STORAGE_BEARER_TOKEN
				);
				const url =
					`${window.config.backend.url}/${window.config.endpoints.project}`.replace(
						":id",
						projectId
					);
				const bearer = `Bearer ${token}`;
				const response = await fetch(url, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: bearer,
					},
				});
				if (!response.ok) {
					throw new Error("Failed to delete project");
				}
				iziToast.success({
					title: "Delete successful",
					message: "Project deleted correctly",
				});
				onDeleteProjectCallback();
			} catch (error) {
				console.error(error);
				iziToast.error({
					title: "Delete project",
					message: "Error deleting project",
				});
			} finally {
				onCloseDialog();
			}
		};

		return (
			<AlertDialog isOpen={isOpen} onClose={onCloseDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete project
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button onClick={onCloseDialog}>Cancel</Button>
							<Button
								colorScheme="red"
								onClick={onDeleteProject}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		);
	}
);

export default DeleteProjectComponent;
