import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
	ChakraProvider,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	Input,
	Box,
	IconButton,
	Text,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import iziToast from "izitoast";
import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";
import "./uploaded-images.css";

const UploadedImagesComponent = React.memo(() => {
	const [files, setFiles] = useState([]);
	const { projectId } = useParams();
	const [s3, setS3] = useState(null);

	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [filesPerPage] = useState(5);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const [currentFiles, setCurrentFiles] = useState([]);
	const [pageNumbers, setPageNumbers] = useState([]);
	// Search term
	const [searchTerm, setSearchTerm] = useState("");
	const bucket = window.config.s3.bucketSubida;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [fileToDelete, setFileToDelete] = useState(null);
	const cancelRef = useRef();

	useEffect(() => {
		const initializeS3 = async () => {
			await Utils.fetchCredentials();
			setS3(new AWS.S3());
		};
		initializeS3();
	}, []);

	useEffect(() => {
		const fetchFiles = async () => {
			if (!s3 || !projectId) return;

			try {
				const params = {
					Bucket: bucket,
					Prefix: `${projectId}/${Globals.IMAGES_DIRECTORY}`,
				};
				const data = await s3.listObjectsV2(params).promise();
				const s3Files = data.Contents.map((item) => ({
					key: item.Key,
					file: {
						name: item.Key.split("/").pop(),
						size: item.Size,
					},
				}));
				setFiles(s3Files);
			} catch (error) {
				console.error("Error fetching files:", error);
				iziToast.error({
					title: "Error",
					message: "Could not load files from S3",
				});
			}
		};

		fetchFiles();
	}, [s3, projectId]);

	useEffect(() => {
		// Filter files based on search term
		const filteredFiles = files.filter((file) =>
			file.file.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		// Pagination logic
		const indexOfLastFile = currentPage * filesPerPage;
		const indexOfFirstFile = indexOfLastFile - filesPerPage;
		const currentFiles = filteredFiles.slice(
			indexOfFirstFile,
			indexOfLastFile
		);
		const pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(filteredFiles.length / filesPerPage);
			i++
		) {
			pageNumbers.push(
				<Button
					key={i}
					onClick={() => paginate(i)}
					variant={currentPage === i ? "solid" : "outline"}
					className="uploaded-image-pagination-item"
				>
					{i}
				</Button>
			);
		}
		setPageNumbers(pageNumbers);
		setCurrentFiles(currentFiles);
	}, [files, currentPage, filesPerPage, searchTerm]);

	const handleSearchInputChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleSearchKeyPress = (event) => {
		if (event.key === "Enter") {
			setCurrentPage(1); // Reset to first page after search
		}
	};

	const confirmDeleteFile = (fileKey) => {
		setFileToDelete(fileKey);
		onOpen();
	};

	const handleDeleteFile = async () => {
		try {
			const params = {
				Bucket: bucket,
				Key: fileToDelete,
			};
			await s3.deleteObject(params).promise();

			setFiles((prevFiles) =>
				prevFiles.filter((file) => file.key !== fileToDelete)
			);

			iziToast.success({
				title: "Success",
				message: "File deleted successfully",
			});
		} catch (error) {
			console.error("Error deleting file:", error);
			iziToast.error({
				title: "Error",
				message: "Could not delete file from S3",
			});
		} finally {
			onClose();
		}
	};

	return (
		<div>
			<ChakraProvider>
				<Box className="uploaded-images-header-tools" mb={1}>
					<Box>
						<Text className="uploaded-images-total-label">
							Total uploaded images: {files.length}
						</Text>
					</Box>
					<Box className="uploaded-images-search">
						<Input
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearchInputChange}
							onKeyPress={handleSearchKeyPress}
						/>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="center"
					mt={1}
					className="uploaded-images-table-container"
				>
					<Table variant="simple" className="uploaded-images-table">
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>Size</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{currentFiles.map((file) => (
								<Tr key={file.key}>
									<Td>{file.file.name}</Td>
									<Td>{Utils.formatBytes(file.file.size)}</Td>
									<Td>
										<IconButton
											colorScheme="blue"
											aria-label="Delete file"
											icon={<DeleteIcon />}
											onClick={() =>
												confirmDeleteFile(file.key)
											}
										/>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>

				<Box display="flex" justifyContent="center" mt={4}>
					{pageNumbers}
				</Box>

				{/* Modal de confirmación de eliminación */}
				<AlertDialog
					isOpen={isOpen}
					leastDestructiveRef={cancelRef}
					onClose={onClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Delete Image
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure? You can't undo this action
								afterwards.
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose}>
									Cancel
								</Button>
								<Button
									colorScheme="red"
									onClick={handleDeleteFile}
									ml={3}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</ChakraProvider>
		</div>
	);
});

export default UploadedImagesComponent;
