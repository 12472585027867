import React, { useState, useCallback, useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Box,
	SimpleGrid,
	Checkbox,
	ChakraProvider,
	Input,
	Text,
} from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import PaginationComponent from "../utils/Pagination";
import * as Utils from "../../utils/utils";

const ProcessedImagesTaskComponent = React.memo(
	({ taskId, images, isOpen, onClose }) => {
		const itemsPerPage = 32;
		const [currentImages, setCurrentImages] = useState([]);
		const [selectedImages, setSelectedImages] = useState([]);
		const [searchTerm, setSearchTerm] = useState("");

		useEffect(() => {
			async function init() {
				await Utils.fetchCredentials();
			}
			init();
		}, []);

		useEffect(() => {
			setSelectedImages([]);
		}, [images]);

		const onChangePageCallback = useCallback((newImages) => {
			setCurrentImages(newImages);
		}, []);

		const toggleImageSelection = (imageId) => {
			setSelectedImages((prevSelectedImages) => {
				const updatedSelectedImages = [...prevSelectedImages];
				const index = updatedSelectedImages.indexOf(imageId);
				if (index > -1) {
					updatedSelectedImages.splice(index, 1);
				} else {
					updatedSelectedImages.push(imageId);
				}
				return updatedSelectedImages;
			});
		};

		const handleSelectAll = () => {
			const filteredImageIds = filteredImages.map((image) => image.id);
			const areAllFilteredSelected = filteredImageIds.every((id) =>
				selectedImages.includes(id)
			);

			if (areAllFilteredSelected) {
				setSelectedImages((prevSelectedImages) =>
					prevSelectedImages.filter(
						(id) => !filteredImageIds.includes(id)
					)
				);
			} else {
				setSelectedImages((prevSelectedImages) => [
					...new Set([...prevSelectedImages, ...filteredImageIds]),
				]);
			}
		};

		const handleInvertSelection = () => {
			const filteredImageIds = filteredImages.map((image) => image.id);
			setSelectedImages((prevSelectedImages) =>
				filteredImageIds.filter(
					(id) => !prevSelectedImages.includes(id)
				)
			);
		};

		const onDownloadSelectedImages = async () => {
			try {
				const zip = new JSZip();
				const folder = zip.folder(`task_${taskId}_images`);

				const imagePromises = selectedImages.map(
					async (image, index) => {
						const imageUrl = `${window.config.s3.cloudFront}/${image}`;
						const response = await fetch(imageUrl);
						const blob = await response.blob();
                                    const imageName = image.split("/").pop();
						folder.file(imageName, blob);
					}
				);

				await Promise.all(imagePromises);

				const zipBlob = await zip.generateAsync({ type: "blob" });
				saveAs(zipBlob, `task_${taskId}_images.zip`);
                        console.log(selectedImages);
				iziToast.success({
					title: "Download successful",
					message: "Images downloaded correctly",
				});
				onClose();
			} catch (error) {
				console.error(error);
				iziToast.error({
					title: "Error",
					message: "Error downloading selected images",
				});
			}
		};

		const filteredImages = images.filter((image) =>
			image.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		return (
			<ChakraProvider>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent
						sx={{
							maxWidth: "80vw",
							width: "80vw",
							maxHeight: "40vw",
							height: "40vw",
						}}
					>
						<ModalHeader>
							<Flex
								justifyContent="space-between"
								alignItems="center"
							>
								<Text>
									Selected images ({selectedImages.length} out
									of {filteredImages.length})
								</Text>
								<Flex>
									<Button
										onClick={handleSelectAll}
										colorScheme="teal"
										variant="solid"
										mr={2}
									>
										{filteredImages.every((image) =>
											selectedImages.includes(image.id)
										)
											? "Unselect all"
											: "Select all"}
									</Button>
									<Button
										onClick={handleInvertSelection}
										colorScheme="orange"
										variant="solid"
										mr={2}
									>
										Invert selection
									</Button>
									<Button
										onClick={onDownloadSelectedImages}
										colorScheme="teal"
										variant="solid"
										mr={8}
									>
										Download
									</Button>
								</Flex>
							</Flex>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Input
								placeholder="Search images"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								mb={4}
							/>
							<SimpleGrid columns={[2, null, 4]} spacing={4}>
								{currentImages.map((image) => (
									<Box
										key={image.id}
										cursor="pointer"
										borderWidth={1}
										borderRadius="lg"
										overflow="hidden"
										onClick={() =>
											toggleImageSelection(image.id)
										}
									>
										<Box p="2">
											<Checkbox
												onChange={() =>
													toggleImageSelection(
														image.id
													)
												}
												isChecked={selectedImages.includes(
													image.id
												)}
											>
												{image.name}
											</Checkbox>
										</Box>
									</Box>
								))}
							</SimpleGrid>
						</ModalBody>
						<ModalFooter>
							<Flex w="100%" justifyContent="center">
								{filteredImages.length > 0 && (
									<PaginationComponent
										items={filteredImages}
										itemsPerPage={itemsPerPage}
										onChangePageCallback={
											onChangePageCallback
										}
									/>
								)}
							</Flex>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
		);
	}
);

export default ProcessedImagesTaskComponent;
