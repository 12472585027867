import React, { useEffect, useState, useCallback } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import ProjectCard from "./ProjectCard";
import NewProjectComponent from "./NewProject";
import UpdateProjectComponent from "./UpdateProject";
import DeleteProjectComponent from "./DeleteProject";
import PaginationComponent from "../utils/Pagination";
import BreadcrumbComponent from "../utils/Breadcrumb";
import EmptyProjectsComponent from "./EmptyProjects";

import * as Globals from "../../utils/globals";
import "./projects.css";

const ProjectsHomeComponent = React.memo(() => {
	const itemsPerPage = 6;
	const [displayEmptyProjectsCard, setEmptyProjectsCard] = useState(false);
	const [projects, setProjects] = useState([]);
	const [selectedProjectId, setSelectedProjectId] = useState(undefined);
	const [selectedProject, setSelectedProject] = useState({
		id: selectedProjectId,
		name: "",
		description: "",
	});

	//Pagination
	const [currentProjects, setCurrentProjects] = useState([]);
	const onChangePageCallback = useCallback((newProjects) => {
		setCurrentProjects(newProjects);
	});

	//Get projects
	const fetchProjects = async () => {
		try {
			const userId = localStorage.getItem(Globals.LOCAL_STORAGE_USER_ID);
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.backend.url}/${userId}/${window.config.endpoints.projects}`;
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error(
					"Network error. Please contact with the support team"
				);
			}
			const responseJson = await response.json();
			const projectsData = responseJson.projectsData;
			setProjects(projectsData);
			setEmptyProjectsCard(projectsData.length === 0);
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Projects",
				message:
					"Error getting projects. Please contact with the support team",
			});
		}
	};

	//Fetch projects when the component is mounted in DOM
	useEffect(() => {
		fetchProjects();
	}, []);

	//Save project
	const onAddProjectCallback = useCallback(() => {
		fetchProjects();
	});

	//Delete project
	const [isDeleteDialogOpen, setIsDeleteDialogStatus] = useState(false);
	const onCloseDeleteDialog = () => setIsDeleteDialogStatus(false);
	const onOpenDeleteDialog = () => setIsDeleteDialogStatus(true);

	const onDeleteProjectClick = (id) => {
		setSelectedProjectId(id);
		onOpenDeleteDialog();
	};

	const onDeleteProjectCallback = useCallback(() => {
		fetchProjects();
	});

	//Update projects
	const [isUpdateModalOpen, setIsUpdateModalStatus] = useState(false);
	const onCloseUpdateModal = () => setIsUpdateModalStatus(false);
	const onOpenUpdateModal = () => setIsUpdateModalStatus(true);

	const onUpdateProjectClick = (id) => {
		setSelectedProjectId(id);
		onOpenUpdateModal();
	};

	useEffect(() => {
		const updatedProject = projects.find(
			(project) => project.id === selectedProjectId
		);
		if (!updatedProject) {
			return;
		}
		setSelectedProject(updatedProject);
	}, [selectedProjectId]);

	const onUpdateProjectCallback = useCallback(() => {
		fetchProjects();
	});

	return (
		<>
			<ChakraProvider>
				{isDeleteDialogOpen && (
					<DeleteProjectComponent
						isOpen={isDeleteDialogOpen}
						projectId={selectedProjectId}
						onDeleteProjectCallback={onDeleteProjectCallback}
						onCloseDialog={onCloseDeleteDialog}
					/>
				)}
				{isUpdateModalOpen && (
					<UpdateProjectComponent
						isOpen={isUpdateModalOpen}
						currentProjectData={selectedProject}
						onCloseModal={onCloseUpdateModal}
						onUpdateProjectCallback={onUpdateProjectCallback}
					/>
				)}
				<Box className="projects-header">
					<BreadcrumbComponent />
					<NewProjectComponent
						onAddProjectCallback={onAddProjectCallback}
					></NewProjectComponent>
				</Box>
				<div className="projects-container">
					<div className="projects-card-container">
						{displayEmptyProjectsCard ? (
							<EmptyProjectsComponent
								onAddProjectCallback={onAddProjectCallback}
							/>
						) : (
							<Grid templateColumns="repeat(6, 1fr)" gap={1}>
								{currentProjects.map((project) => (
									<GridItem key={project.id}>
										<ProjectCard
											id={project.id}
											name={project.name}
											description={project.description}
											onUpdateProjectClick={
												onUpdateProjectClick
											}
											onDeleteProjectClick={
												onDeleteProjectClick
											}
										/>
									</GridItem>
								))}
							</Grid>
						)}
					</div>
					{projects.length > 0 && (
						<PaginationComponent
							items={projects}
							itemsPerPage={itemsPerPage}
							onChangePageCallback={onChangePageCallback}
						/>
					)}
				</div>
			</ChakraProvider>
		</>
	);
});

export default ProjectsHomeComponent;
