import React from "react";

import FooterComponent from "./Footer";
import HeaderComponent from "./Header";
import SidebarComponent from "./Sidebar";
import DashboardComponent from "../dashboard/Dashboard";
import ProjectsHomeComponent from "../projects/ProjectsHome";
import ImagesHomeComponent from "../images/ImagesHome.jsx";
import TasksHomeComponent from "../tasks/TasksHome.jsx";
import WorkflowHomeComponent from "../workflow/WorkflowHome.jsx";

import * as Globals from "../../utils/globals";

const HomeComponent = React.memo(({ selectedOption }) => {
	const renderComponent = () => {
		switch (selectedOption) {
			case Globals.DASHBOARD_OPTION:
				return <DashboardComponent />;
			case Globals.PROJECTS_OPTION:
				return <ProjectsHomeComponent />;
			case Globals.IMAGES_OPTION:
				return <ImagesHomeComponent />;
			case Globals.TASKS_OPTION:
				return <TasksHomeComponent />;
			case Globals.WORKFLOW_OPTION:
				return <WorkflowHomeComponent />;
		}
	};

	return (
		<>
			<HeaderComponent isLoggedIn={true}></HeaderComponent>
			<div style={{ display: "flex", height: "90%" }}>
				<SidebarComponent />
				<div
					style={{
						width: "100%",
					}}
				>
					{renderComponent()}
				</div>
			</div>
			<FooterComponent></FooterComponent>
		</>
	);
});

export default HomeComponent;
