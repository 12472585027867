import React, { useState, useEffect } from "react";
import { Flex, Button } from "@chakra-ui/react";

import "./pagination.css";

const PaginationComponent = React.memo(
	({ items, itemsPerPage, onChangePageCallback }) => {
		const [currentPage, setCurrentPage] = useState(1);
		const [pageNumbers, setPageNumbers] = useState([]);

		const initializePageNumbers = () => {
			if (!items) {
				return;
			}
			const pageNumbersAux = [];
			for (let i = 1; i <= Math.ceil(items.length / itemsPerPage); i++) {
				pageNumbersAux.push(i);
			}
			setPageNumbers(pageNumbersAux);
		};

		const filterItems = () => {
			if (!items) {
				return;
			}
			const indexOfLastItem = currentPage * itemsPerPage;
			const indexOfFirstItem = indexOfLastItem - itemsPerPage;
			const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
			onChangePageCallback(currentItems);
		};

		useEffect(() => {
			initializePageNumbers();
			filterItems();
		}, [items]);

		useEffect(() => {
			filterItems();
		}, [currentPage]);

		const handlePageChange = (pageNumber) => {
			setCurrentPage(pageNumber);
		};

		return (
			<Flex justifyContent="center" mt={4}>
				{pageNumbers.map((number) => (
					<Button
						key={number}
						onClick={() => handlePageChange(number)}
						className="pagination-item"
					>
						{number}
					</Button>
				))}
			</Flex>
		);
	}
);

export default PaginationComponent;
