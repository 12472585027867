import React from "react";
import NewProjectComponent from "./NewProject";

import "./emptyProjects.css";

const EmptyProjectsComponent = React.memo(({ onAddProjectCallback }) => {
	return (
		<div className="empty-projects-container">
			<div className="empty-projects-card">
				<div className="empty-projects-image-container">
					<img src="/images/projects.jpg" />
				</div>
				<div className="empty-projects-text-container">
					<p className="empty-projects-subtitle">
						You have no projects yet
					</p>
					<p className="empty-projects-description">
						Projects help you organise your data. Begin by creating
						your first project.
					</p>
					<NewProjectComponent
						onAddProjectCallback={onAddProjectCallback}
					></NewProjectComponent>
				</div>
			</div>
		</div>
	);
});

export default EmptyProjectsComponent;
