import { Position, MarkerType } from 'reactflow';
import * as Globals from './globals';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
	// https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
	const {
		width: intersectionNodeWidth,
		height: intersectionNodeHeight,
		positionAbsolute: intersectionNodePosition,
	} = intersectionNode;
	const targetPosition = targetNode.positionAbsolute;

	const w = intersectionNodeWidth / 2;
	const h = intersectionNodeHeight / 2;

	const x2 = intersectionNodePosition.x + w;
	const y2 = intersectionNodePosition.y + h;
	const x1 = targetPosition.x + targetNode.width / 2;
	const y1 = targetPosition.y + targetNode.height / 2;

	const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
	const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
	const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
	const xx3 = a * xx1;
	const yy3 = a * yy1;
	const x = w * (xx3 + yy3) + x2;
	const y = h * (-xx3 + yy3) + y2;

	return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
	const n = { ...node.positionAbsolute, ...node };
	const nx = Math.round(n.x);
	const ny = Math.round(n.y);
	const px = Math.round(intersectionPoint.x);
	const py = Math.round(intersectionPoint.y);

	if (px <= nx + 1) {
		return Position.Left;
	}
	if (px >= nx + n.width - 1) {
		return Position.Right;
	}
	if (py <= ny + 1) {
		return Position.Top;
	}
	if (py >= n.y + n.height - 1) {
		return Position.Bottom;
	}

	return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
	const sourceIntersectionPoint = getNodeIntersection(source, target);
	const targetIntersectionPoint = getNodeIntersection(target, source);

	const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
	const targetPos = getEdgePosition(target, targetIntersectionPoint);

	return {
		sx: sourceIntersectionPoint.x,
		sy: sourceIntersectionPoint.y,
		tx: targetIntersectionPoint.x,
		ty: targetIntersectionPoint.y,
		sourcePos,
		targetPos,
	};
}

export function createNodesAndEdges() {
	const nodes = [];
	const edges = [];
	const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

	nodes.push({ id: 'target', data: { label: 'Target' }, position: center });

	for (let i = 0; i < 8; i++) {
		const degrees = i * (360 / 8);
		const radians = degrees * (Math.PI / 180);
		const x = 250 * Math.cos(radians) + center.x;
		const y = 250 * Math.sin(radians) + center.y;

		nodes.push({ id: `${i}`, data: { label: 'Source' }, position: { x, y } });

		edges.push({
			id: `edge-${i}`,
			target: 'target',
			source: `${i}`,
			type: 'floating',
			markerEnd: {
				type: MarkerType.Arrow,
			},
		});
	}

	return { nodes, edges };
}

export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return (
		parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	);
};

export async function listFilesInS3Bucket(bucketName, folderPath) {
	const s3 = new AWS.S3();
	const params = {
		Bucket: bucketName,
		Prefix: folderPath,
	};

	try {
		const data = await s3.listObjectsV2(params).promise();
		return data.Contents.map(item => ({
			id: item.Key,
			name: item.Key.split('/').pop(),
		}));
	} catch (error) {
		console.error('Error fetching files from S3:', error);
		throw error;
	}
};

export async function fetchCredentials () {
	try {
		const token = localStorage.getItem(
			Globals.LOCAL_STORAGE_BEARER_TOKEN
		);
		const url = `${window.config.backend.url}/${window.config.endpoints.credentials}`;
		const bearer = `Bearer ${token}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: bearer,
			},
		});
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const jsonData = await response.json();
		const credentials = jsonData.credentials;
		if (!credentials) {
			return;
		}
		AWS.config.update({
			region: window.config.s3.region,
			credentials: new AWS.Credentials(
				credentials.AccessKeyId,
				credentials.SecretAccessKey,
				credentials.SessionToken
			),
		});
	} catch (error) {
		console.error(error);
	}
};

export function formatDate(timestamp) {
	if (!timestamp) {
		return "";
	}
	const date = new Date(timestamp);
	const day = String(date.getDate()).padStart(2, '0'); 
	const month = String(date.getMonth() + 1).padStart(2, '0'); 
	const year = date.getFullYear();

	const formattedDate = `${day}-${month}-${year}`;
	return formattedDate;
}