import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Button,
	Input,
	Box,
	Progress,
	IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import iziToast from "izitoast";
import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";

import "./upload-images.css";

const UploadImagesComponent = React.memo(() => {
	const [files, setFiles] = useState([]);
	const { projectId } = useParams();
	const [s3, setS3] = useState(null);

	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [filesPerPage] = useState(5);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const [currentFiles, setCurrentFiles] = useState([]);
	const [pageNumbers, setPageNumbers] = useState([]);

	const fileInputRef = useRef(null);

	// Search term
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const initializeS3 = async () => {
			await Utils.fetchCredentials();
			setS3(new AWS.S3());
		};
		initializeS3();
	}, []);

	useEffect(() => {
		// Filter files based on search term
		const filteredFiles = files.filter((file) =>
			file.file.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		// Pagination logic
		const indexOfLastFile = currentPage * filesPerPage;
		const indexOfFirstFile = indexOfLastFile - filesPerPage;
		const currentFiles = filteredFiles.slice(
			indexOfFirstFile,
			indexOfLastFile
		);
		const pageNumbers = [];
		for (
			let i = 1;
			i <= Math.ceil(filteredFiles.length / filesPerPage);
			i++
		) {
			pageNumbers.push(
				<Button
					key={i}
					onClick={() => paginate(i)}
					variant={currentPage === i ? "solid" : "outline"}
					className="upload-images-pagination-item"
				>
					{i}
				</Button>
			);
		}
		setPageNumbers(pageNumbers);
		setCurrentFiles(currentFiles);
	}, [files, currentPage, filesPerPage, searchTerm]);

	const handleAddImages = (event) => {
		const selectedFiles = Array.from(event.target.files);
		const newFiles = selectedFiles.map((file) => ({
			file,
			key: `${projectId}/${Globals.IMAGES_DIRECTORY}/${file.name}`,
			progress: 0,
			animated: false,
			progressColor: "blue",
		}));
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
	};

	const clearInput = () => {
		setFiles([]);
		setSearchTerm("");
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	const handleUpload = async () => {
		if (files.length === 0) {
			return;
		}
		const s3 = new AWS.S3();
		const bucket = window.config.s3.bucketSubida;

		const uploadPromises = files.map((file) => {
			return new Promise((resolve, reject) => {
				const params = {
					Bucket: bucket,
					Key: file.key,
					Body: file.file,
					ContentType: file.file.type,
				};

				const uploadInstance = s3.upload(params, (err, data) => {
					if (err) {
						console.error(`Error uploading file ${file.key}:`, err);
						reject(`Error uploading file ${file.key}.`);
					} else {
						resolve(`File ${file.key} uploaded correctly.`);
					}
				});

				uploadInstance.on("httpUploadProgress", (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setFiles((prevFiles) => {
						if (!prevFiles) {
							return prevFiles; // Return current state if null or undefined
						}
						const updatedFiles = prevFiles.map((prevFile) => {
							if (prevFile.key === params.Key) {
								const progressColor =
									progress === 100 ? "green" : "blue";
								const animated = progress < 100;
								return {
									...prevFile,
									progress,
									animated,
									progressColor,
								};
							} else {
								return prevFile;
							}
						});
						return updatedFiles;
					});
				});
			});
		});

		try {
			await Promise.all(uploadPromises);
			iziToast.success({
				title: "Upload successful",
				message: "All files uploaded successfully",
			});
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Upload failed",
				message: "Error uploading some files",
			});
		}
	};

	const handleSearchInputChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleSearchKeyPress = (event) => {
		if (event.key === "Enter") {
			setCurrentPage(1); // Reset to first page after search
		}
	};

	const handleDeleteFile = (fileKey) => {
		// Remove the file from the state
		setFiles((prevFiles) =>
			prevFiles.filter((file) => file.key !== fileKey)
		);
	};

	return (
		<div>
			<ChakraProvider>
				<Box className="upload-images-header-tools">
					<Box display="flex" gap={4}>
						<Input
							type="file"
							onChange={handleAddImages}
							multiple
							ref={fileInputRef}
						/>
						<Button
							colorScheme="teal"
							variant="solid"
							onClick={handleUpload}
							isDisabled={files.length === 0}
							style={{ marginLeft: "10px" }}
						>
							Upload
						</Button>
						<Button
							colorScheme="teal"
							variant="solid"
							onClick={clearInput}
							isDisabled={files.length === 0}
						>
							Clear
						</Button>
					</Box>
					<Box className="upload-images-search">
						<Input
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearchInputChange}
							onKeyPress={handleSearchKeyPress}
						/>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="center"
					mt={1}
					className="upload-images-table-container"
				>
					<Table variant="simple" className="upload-images-table">
						<Thead>
							<Tr>
								<Th>Name</Th>
								<Th>Size</Th>
								<Th>Progress</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{currentFiles.map((file) => (
								<Tr key={file.key}>
									<Td>{file.file.name}</Td>
									<Td>{Utils.formatBytes(file.file.size)}</Td>
									<Td>
										{file.progress > 0 && (
											<Progress
												hasStripe
												value={file.progress}
												size="sm"
												isAnimated={file.animated}
												colorScheme={file.progressColor}
											/>
										)}
									</Td>
									<Td>
										<IconButton
											colorScheme="blue"
											aria-label="Delete file"
											icon={<DeleteIcon />}
											onClick={() =>
												handleDeleteFile(file.key)
											}
										/>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>

				{/* Paginator */}
				<Box display="flex" justifyContent="center" mt={4}>
					{pageNumbers}
				</Box>
			</ChakraProvider>
		</div>
	);
});

export default UploadImagesComponent;
