import React from "react";
import { useNavigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import {
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import { Avatar } from "@chakra-ui/react";

import * as Globals from "../../utils/globals";

import "./header.css";

const HeaderComponent = React.memo(({ isLoggedIn }) => {
	const navigate = useNavigate();

	const logout = async () => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.loginBackend.url}/${window.config.endpoints.logout}`;
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					Authorization: bearer,
				},
			});
			const success = response.status === 200;
			if (!success) {
				throw new Error("Some error happened when loging out");
			}
			localStorage.removeItem(Globals.LOCAL_STORAGE_BEARER_TOKEN);
			localStorage.removeItem(Globals.LOCAL_STORAGE_USER_ID);
			navigate("/login");
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error loging out.",
				description: error.message,
			});
		}
	};

	const CustomMenu = () => {
		return (
			<ChakraProvider>
				<Box p="4">
					<Flex alignItems="center">
						<Menu>
							<MenuButton
								className="menu-button"
								as={Avatar}
								aria-label="Options"
								src="https://bit.ly/broken-link"
								variant="outline"
								colorScheme="teal"
							/>
							<MenuList>
								<MenuItem
									className="menu-item"
									onClick={logout}
								>
									Logout
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Box>
			</ChakraProvider>
		);
	};

	return (
		<header className="header">
			<div className="logo-container">
				<img
					src="../../../../icons/Oblicuo_color.png"
					className="logo"
				/>
			</div>
			<div>{isLoggedIn && <CustomMenu></CustomMenu>}</div>
		</header>
	);
});

export default HeaderComponent;
