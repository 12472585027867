import React, { useState, useEffect, useCallback } from "react";
import {
	ChakraProvider,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	Box,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Input,
	Textarea,
	Image,
	List,
	ListItem,
	ListIcon,
	useDisclosure,
} from "@chakra-ui/react";
import {
	DeleteIcon,
	EditIcon,
	WarningTwoIcon,
	SpinnerIcon,
	CheckIcon,
	SettingsIcon,
	DownloadIcon,
	SunIcon,
} from "@chakra-ui/icons";
import {
	AiOutlinePartition,
	AiOutlineCaretRight,
	AiOutlineReload,
} from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import NewTaskComponent from "./NewTask";
import PaginationComponent from "../utils/Pagination";
import BreadcrumbComponent from "../utils/Breadcrumb";
import DeleteTaskComponent from "./DeleteTask";
import ProcessedImagesTaskComponent from "./ProcessedImagesTask";

import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";

import "./tasks.css";

const TasksHomeComponent = React.memo(() => {
	const itemsPerPage = 5;

	const [tasks, setTasks] = useState([]);
	const [selectedTask, setSelectedTask] = useState({
		id: "",
		name: "",
		description: "",
	});
	const [errorDetails, setErrorDetails] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	const { projectId, userId } = useParams();

	// Nuevo estado para control de orden
	const [sortColumn, setSortColumn] = useState("creation_date");
	const [sortDirection, setSortDirection] = useState("asc");

	//S3
	const [s3, setS3] = useState(null);
	useEffect(() => {
		const initializeS3 = async () => {
			await Utils.fetchCredentials();
			setS3(new AWS.S3());
		};
		initializeS3();
	}, []);

	// Control de modales
	const {
		isOpen: isErrorModalOpen,
		onOpen: onErrorModalOpen,
		onClose: onErrorModalClose,
	} = useDisclosure();

	const {
		isOpen: isEditModalOpen,
		onOpen: onEditModalOpen,
		onClose: onEditModalClose,
	} = useDisclosure();

	// Mapa de estados a íconos
	const statusIconMap = {
		loading: {
			icon: <SettingsIcon />,
			color: "blue.500",
			label: "Preparing data",
		},
		running: {
			icon: <SpinnerIcon />,
			color: "teal.500",
			label: "Running",
		},
		completed: {
			icon: <CheckIcon />,
			color: "green.500",
			label: "Completed",
		},
		failed: { icon: <WarningTwoIcon />, color: "red.500", label: "Failed" },
	};

	// Images selection
	const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
	const [images, setImages] = useState([]);

	// Navegación
	const navigate = useNavigate();
	const onWorkflowTask = (data) => {
		if (projectId) {
			navigate(
				Globals.WORKFLOW_ROUTE.replace(":projectId", projectId).replace(
					":taskId",
					data.id
				)
			);
		}
		if (userId) {
			navigate(
				Globals.USERS_TASKS_WORKFLOW_ROUTE.replace(
					":userId",
					userId
				).replace(":taskId", data.id)
			);
		}
	};

	// Control de paginación
	const [currentTasks, setCurrentTasks] = useState([]);
	const onChangePageCallback = useCallback((newTasks) => {
		setCurrentTasks(newTasks);
	});

	const fetchTasks = async () => {
		try {
			const body = {
				q: searchTerm,
			};
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			let url =
				`${window.config.backend.url}/${window.config.endpoints.projectstasks}`.replace(
					":id",
					projectId
				);
			if (userId) {
				url =
					`${window.config.backend.url}/${window.config.endpoints.usertasks}`.replace(
						":id",
						userId
					);
			}
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: bearer,
				},
				body: JSON.stringify(body),
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const jsonData = await response.json();
			setTasks(jsonData.tasks);
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Tasks",
				message: "Error getting tasks",
			});
		}
	};
	useEffect(() => {
		fetchTasks();
	}, []);

	// Guardar tarea
	const onAddTaskCallback = useCallback(() => {
		fetchTasks();
	});

	// Eliminar tarea
	const [isDeleteDialogOpen, setIsDeleteDialogStatus] = useState(false);
	const onCloseDeleteDialog = () => setIsDeleteDialogStatus(false);
	const onOpenDeleteDialog = () => setIsDeleteDialogStatus(true);

	const onDeletetTaskClick = (data) => {
		setSelectedTask(data);
		onOpenDeleteDialog();
	};

	// Callback de eliminación
	const onDeleteTaskCallback = useCallback(() => {
		fetchTasks();
		setSelectedTask({
			id: "",
			name: "",
			description: "",
		});
	});

	// Manejar la edición de una tarea
	const onEditRow = (data) => {
		setSelectedTask(data);
		onEditModalOpen();
	};

	const handleEditChange = (e) => {
		const { name, value } = e.target;
		setSelectedTask((prevTask) => ({
			...prevTask,
			[name]: value,
		}));
	};

	const onUpdateTask = async () => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.task}`.replace(
					":id",
					selectedTask.id
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					Authorization: bearer,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					name: selectedTask.name,
					description: selectedTask.description,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to update task");
			}
			iziToast.success({
				title: "Success",
				message: "Task updated successfully",
			});
			await fetchTasks();
			onEditModalClose();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Failed to update task",
			});
		}
	};

	const onValidateTask = async (task) => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.validate}`.replace(
					":id",
					task.id
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to validate task");
			}
			const responseData = await response.json();
			if (!responseData.success) {
				iziToast.error({
					title: "Error",
					message:
						"Task is not valid. Click on the error icon for details",
				});
			}
			return responseData.success;
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Failed to validate task",
			});
			return false;
		}
	};

	const onRunTask = async (task) => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.task}`.replace(
					":id",
					task.id
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					Authorization: bearer,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					status: Globals.TASK_STATUS.running,
					processingDate: new Date(),
					pipelineStatus: "null",
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to update task");
			}
			iziToast.success({
				title: "Success",
				message: "Task updated successfully",
			});
			await fetchTasks();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Failed to update task",
			});
		}
	};

	// Obtener detalles del error
	const fetchErrorDetails = async (taskId) => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.errors}`.replace(
					":id",
					taskId
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) throw new Error("Failed to fetch error details");
			const errorData = await response.json();
			setErrorDetails(errorData.taskErrors.errors);
			onErrorModalOpen();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Failed to fetch error details",
			});
		}
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	// Manejar la búsqueda al presionar Enter
	const handleSearchKeyPress = (event) => {
		if (event.key === "Enter") {
			fetchTasks();
		}
	};

	const handleSort = (column) => {
		const isAsc = sortColumn === column && sortDirection === "asc";
		setSortDirection(isAsc ? "desc" : "asc");
		setSortColumn(column);
	};

	// Función para ordenar las tareas
	const sortedTasks = currentTasks.sort((a, b) => {
		if (sortColumn) {
			const valA = a[sortColumn] || "";
			const valB = b[sortColumn] || "";
			if (sortDirection === "asc") {
				return valA > valB ? 1 : valA < valB ? -1 : 0;
			} else {
				return valA < valB ? 1 : valA > valB ? -1 : 0;
			}
		}
		return 0;
	});

	const onDisplayResults = async (task) => {
		try {
			const bucketName = window.config.s3.bucketSubida;
			const folderPath = `${task.id_project}/${task.id}/${Globals.OUTPUT_IMAGES_DIRECTORY}`;
			const images = await Utils.listFilesInS3Bucket(
				bucketName,
				folderPath
			);
			setImages(images);
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Error getting uploaded images",
			});
			setImages([]);
		}
	};

	useEffect(() => {
		if (images.length > 0) {
			setIsImagesModalOpen(true);		
		}
	}, [images]);

	const onDownloadImages = async (task) => {
		try {
			const zip = new JSZip();
			const folder = zip.folder(`task_${task.id}_images`);
			const bucketName = window.config.s3.bucketSubida;
			const folderPath = `${task.id_project}/${task.id}/${Globals.OUTPUT_IMAGES_DIRECTORY}`;

			const images = await Utils.listFilesInS3Bucket(
				bucketName,
				folderPath
			);

			const imagePromises = images.map(async (image, index) => {
				const imageUrl = `${window.config.s3.cloudFront}/${image.id}`;
				const response = await fetch(imageUrl);
				const blob = await response.blob();
				folder.file(image.name, blob);
			});

			await Promise.all(imagePromises);

			const zipBlob = await zip.generateAsync({ type: "blob" });
			saveAs(zipBlob, `task_${task.id}_images.zip`);
		} catch (error) {
			console.error("Error al descargar las imágenes:", error);
		}
	};

	return (
		<>
			<ChakraProvider>
				<DeleteTaskComponent
					isOpen={isDeleteDialogOpen}
					idTask={selectedTask.id}
					onDeleteTaskCallback={onDeleteTaskCallback}
					onCloseDialog={onCloseDeleteDialog}
				/>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					className="tasks-header"
				>
					<BreadcrumbComponent />
					<NewTaskComponent
						projectId={projectId}
						onAddTaskCallback={onAddTaskCallback}
					/>
				</Box>
				<Box
					className="tasks-search-container"
					display="flex"
					justifyContent="space-between"
					mt={4}
				>
					<Input
						placeholder="Search..."
						value={searchTerm}
						title="Press Enter key to search"
						onChange={handleSearchChange}
						onKeyPress={handleSearchKeyPress} // Evento para ejecutar la búsqueda al presionar Enter
						width="300px"
					/>
					<IconButton
						aria-label="Refresh Data"
						icon={<AiOutlineReload />}
						onClick={fetchTasks}
						colorScheme="teal"
						title="Refresh"
					/>
				</Box>

				<Box display="flex" mt={4} className="tasks-container">
					<Table variant="simple" className="tasks-custom-table">
						<Thead>
							<Tr>
								<Th
									onClick={() => handleSort("name")}
									className="tasks-name-th"
								>
									Name{" "}
									{sortColumn === "name"
										? sortDirection === "asc"
											? "▲"
											: "▼"
										: ""}
								</Th>
								<Th
									onClick={() => handleSort("project")}
									className="tasks-project-th"
								>
									Project{" "}
									{sortColumn === "project"
										? sortDirection === "asc"
											? "▲"
											: "▼"
										: ""}
								</Th>
								<Th
									onClick={() => handleSort("creation_date")}
									className="tasks-type-th"
								>
									Creation date{" "}
									{sortColumn === "creation_date"
										? sortDirection === "asc"
											? "▲"
											: "▼"
										: ""}
								</Th>
								<Th
									onClick={() =>
										handleSort("completion_date")
									}
									className="tasks-type-th"
								>
									Completion date{" "}
									{sortColumn === "completion_date"
										? sortDirection === "asc"
											? "▲"
											: "▼"
										: ""}
								</Th>
								<Th className="tasks-status-th">Status</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody className="tasks-tbody">
							{currentTasks.map((row) => (
								<Tr
									key={row.id}
									className="tasks-custom-table-tr"
								>
									<Td className="tasks-td">
										<Tooltip
											label={row.description}
											aria-label={row.description}
										>
											{row.name}
										</Tooltip>
									</Td>
									<Td className="tasks-td">{row.project}</Td>
									<Td className="tasks-td">
										<Tooltip
											label={row.creation_date}
											aria-label={row.creation_date}
										>
											{Utils.formatDate(
												row.creation_date
											)}
										</Tooltip>
									</Td>
									<Td className="tasks-td">
										<Tooltip
											label={row.completion_date}
											aria-label={row.completion_date}
										>
											{Utils.formatDate(
												row.completion_date
											)}
										</Tooltip>
									</Td>
									<Td className="tasks-td">
										<Tooltip
											label={
												statusIconMap[row.status].label
											}
											aria-label={row.status}
										>
											{row.status ===
											Globals.TASK_STATUS.failed ? (
												<IconButton
													aria-label="View Errors"
													icon={
														statusIconMap[
															row.status
														].icon
													}
													colorScheme="red"
													size="sm"
													ml={2}
													onClick={() =>
														fetchErrorDetails(
															row.id
														)
													}
												/>
											) : (
												<Box
													as="span"
													color={
														statusIconMap[
															row.status
														].color
													}
												>
													{
														statusIconMap[
															row.status
														].icon
													}
												</Box>
											)}
										</Tooltip>
									</Td>
									<Td>
										<Tooltip label="Define workflow">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="Workflow"
												icon={<AiOutlinePartition />}
												onClick={() =>
													onWorkflowTask(row)
												}
												className="tasks-option-item"
												isDisabled={
													row.status !==
														Globals.TASK_STATUS
															.loading &&
													row.status !==
														Globals.TASK_STATUS
															.failed &&
													row.status !==
														Globals.TASK_STATUS
															.completed
												}
											/>
										</Tooltip>
										<Tooltip label="Run">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="Run"
												icon={<AiOutlineCaretRight />}
												onClick={async () => {
													setSelectedTask(row);
													const validTask =
														await onValidateTask(
															row
														);
													if (validTask) {
														onRunTask(row);
													}
													fetchTasks();
												}}
												className="tasks-option-item"
												isDisabled={
													row.status !==
														Globals.TASK_STATUS
															.loading &&
													row.status !==
														Globals.TASK_STATUS
															.failed &&
													row.status !==
														Globals.TASK_STATUS
															.completed
												}
											/>
										</Tooltip>
										<Tooltip label="View results">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="View results"
												icon={<SunIcon />}
												className="tasks-option-item"
												onClick={() =>
													onDisplayResults(row)
												}
												isDisabled={
													row.status !==
													Globals.TASK_STATUS
														.completed
												}
											/>
										</Tooltip>
										<Tooltip label="Download output images">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="Download output Images"
												icon={<DownloadIcon />}
												className="tasks-option-item"
												onClick={() =>
													onDownloadImages(row)
												}
												isDisabled={
													row.status !==
													Globals.TASK_STATUS
														.completed
												}
											/>
										</Tooltip>
										<Tooltip label="Edit">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="Edit"
												icon={<EditIcon />}
												onClick={() => onEditRow(row)}
												className="tasks-option-item"
												isDisabled={
													row.status !==
														Globals.TASK_STATUS
															.loading &&
													row.status !==
														Globals.TASK_STATUS
															.failed &&
													row.status !==
														Globals.TASK_STATUS
															.completed
												}
											/>
										</Tooltip>

										<Tooltip label="Delete">
											<IconButton
												variant="outline"
												colorScheme="blue"
												aria-label="Delete"
												icon={<DeleteIcon />}
												onClick={() =>
													onDeletetTaskClick(row)
												}
												className="tasks-option-item"
												isDisabled={
													row.status !==
														Globals.TASK_STATUS
															.loading &&
													row.status !==
														Globals.TASK_STATUS
															.failed &&
													row.status !==
														Globals.TASK_STATUS
															.completed
												}
											/>
										</Tooltip>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
				<PaginationComponent
					items={tasks}
					itemsPerPage={itemsPerPage}
					onChangePageCallback={onChangePageCallback}
				/>
				{/* Modal para editar tarea */}
				<Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Edit Task</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Input
								placeholder="Task Name"
								name="name"
								value={selectedTask.name}
								onChange={handleEditChange}
								mb={4}
							/>
							<Textarea
								placeholder="Task Description"
								name="description"
								value={selectedTask.description}
								onChange={handleEditChange}
								rows={5}
							/>
						</ModalBody>
						<ModalFooter>
							<Button colorScheme="blue" onClick={onUpdateTask}>
								Save
							</Button>
							<Button ml={3} onClick={onEditModalClose}>
								Cancel
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>

				{/* Modal para Detalles de Errores */}
				<Modal
					isOpen={isErrorModalOpen}
					onClose={onErrorModalClose}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Error Details</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<List spacing={3}>
								{errorDetails.map((error, index) => (
									<ListItem key={index}>
										<ListIcon
											as={WarningTwoIcon}
											color="red.500"
										/>
										{error.message}
									</ListItem>
								))}
							</List>
						</ModalBody>
						<ModalFooter>
							<Button onClick={onErrorModalClose}>Close</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<ProcessedImagesTaskComponent
					taskId={selectedTask.id}
					isOpen={isImagesModalOpen}
					onClose={() => setIsImagesModalOpen(false)}
					images={images}
				/>
			</ChakraProvider>
		</>
	);
});

export default TasksHomeComponent;
