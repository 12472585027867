import React, { useCallback, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import {
	Icon,
	Tooltip,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
	Checkbox,
	useDisclosure,
} from "@chakra-ui/react";
import {
	AiFillCodepenSquare,
	AiFillDelete,
	AiFillSetting,
} from "react-icons/ai";
import { ChakraProvider } from "@chakra-ui/react";

import "./anonymizeNode.css";

const AnonymizeNodeComponent = React.memo(({ id, data }) => {
	const { setNodes, setEdges } = useReactFlow();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isChecked, setIsChecked] = useState(true);

	const onDeleteNode = useCallback(() => {
		setNodes((nodes) => nodes.filter((node) => node.id !== id));
		setEdges((edges) => edges.filter((edge) => edge.source !== id));
	}, [id, setNodes, setEdges]);

	const handleCheckboxChange = (e) => {
		setIsChecked(e.target.checked);
	};

	const handleSave = () => {
		console.log("Settings saved with:", isChecked);
		onClose();
	};

	return (
		<>
			<ChakraProvider>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Settings</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Checkbox
								isChecked={isChecked}
								onChange={handleCheckboxChange}
							>
								Erase people
							</Checkbox>
						</ModalBody>

						<ModalFooter>
							<Button
								colorScheme="blue"
								mr={3}
								onClick={handleSave}
							>
								Guardar
							</Button>
							<Button variant="ghost" onClick={onClose}>
								Cancelar
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
			<div className="anonymize-node">
				<header className="anonymize-node-header">
					<h1 className="anonymize-node-header-text">
						{data.label}
					</h1>
				</header>
				<div className="anonymize-node-body">
					<Icon as={AiFillCodepenSquare} boxSize="2rem" />
					<Handle
						position={Position.Left}
						type="target"
						isConnectableStart={false}
					/>
					<Handle position={Position.Right} type="source" />
				</div>
				<div className="anonymize-node-options">
					<Tooltip label="Settings">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillSetting />}
							onClick={onOpen}
						/>
					</Tooltip>
					<Tooltip label="Delete">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillDelete />}
							onClick={() => onDeleteNode()}
						/>
					</Tooltip>
				</div>
			</div>
		</>
	);
});

export default AnonymizeNodeComponent;
