import React from "react";

import "./footer.css";

const FooterComponent = React.memo(() => {
	return (
		<>
			<footer className="footer">
				<p>
					&copy; {new Date().getFullYear()} Voxel3D S.L.U. All rights
					reserved.
				</p>
			</footer>
		</>
	);
});

export default FooterComponent;
