import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import "./breadcrumb.css";

const BreadcrumbComponent = React.memo(() => {
	const location = useLocation();
	const pathNames = location.pathname.split("/").filter((x) => x);

	const routeAliases = {
		projects: "Projects",
		tasks: "Tasks",
		images: "Images",
		workflow: "Workflow",
	};

	return (
		<Breadcrumb separator=">" className="breadcrumb">
			{pathNames.map((name, index) => {
				const routeTo = `/${pathNames.slice(0, index + 1).join("/")}`;
				const isLast = index === pathNames.length - 1;
				const displayName = routeAliases[name];

				if (!displayName) {
					return;
				}

				return (
					<BreadcrumbItem key={name} isCurrentPage={isLast}>
						<BreadcrumbLink
							as={isLast ? "span" : Link}
							to={isLast ? undefined : routeTo}
						>
							{displayName}
						</BreadcrumbLink>
					</BreadcrumbItem>
				);
			})}
		</Breadcrumb>
	);
});

export default BreadcrumbComponent;
