import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Flex,
	Box,
	SimpleGrid,
	Checkbox,
	ChakraProvider,
	Input,
	Text,
} from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import PaginationComponent from "../utils/Pagination";
import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";

const ImagesTaskComponent = React.memo(({ images, isOpen, onClose }) => {
	const { taskId } = useParams();

	const itemsPerPage = 32;
	const [currentImages, setCurrentImages] = useState([]);
	const [selectedImages, setSelectedImages] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	const loadTaskImages = async () => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const bearer = `Bearer ${token}`;
			const url = `${window.config.backend.url}/tasks/${taskId}/images`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to fetch task images");
			}

			const data = await response.json();
			if (Object.keys(data).length > 0) {
				setSelectedImages(data.taskImages.images.images);
			}
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Error fetching task images",
			});
		}
	};

	useEffect(() => {
		// Cargar las imágenes de la tarea cuando el modal se abre
		if (isOpen) {
			loadTaskImages();
		}
	}, [isOpen]);

	useEffect(() => {
		async function init() {
			await Utils.fetchCredentials();
		}
		init();
	}, []);

	const onChangePageCallback = useCallback((newImages) => {
		setCurrentImages(newImages);
	}, []);

	const toggleImageSelection = (imageId) => {
		setSelectedImages((prevSelectedImages) => {
			const updatedSelectedImages = [...prevSelectedImages];
			const index = updatedSelectedImages.indexOf(imageId);
			if (index > -1) {
				updatedSelectedImages.splice(index, 1);
			} else {
				updatedSelectedImages.push(imageId);
			}
			return updatedSelectedImages;
		});
	};

	const handleSelectAll = () => {
		const filteredImageIds = filteredImages.map((image) => image.id);
		const areAllFilteredSelected = filteredImageIds.every((id) =>
			selectedImages.includes(id)
		);

		if (areAllFilteredSelected) {
			setSelectedImages((prevSelectedImages) =>
				prevSelectedImages.filter(
					(id) => !filteredImageIds.includes(id)
				)
			);
		} else {
			setSelectedImages((prevSelectedImages) => [
				...new Set([...prevSelectedImages, ...filteredImageIds]),
			]);
		}
	};

	const handleInvertSelection = () => {
		const filteredImageIds = filteredImages.map((image) => image.id);
		setSelectedImages((prevSelectedImages) =>
			filteredImageIds.filter((id) => !prevSelectedImages.includes(id))
		);
	};

	const onSaveSelectedImages = async () => {
		try {
			const body = {
				images: selectedImages,
			};
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const bearer = `Bearer ${token}`;
			const url =
				`${window.config.backend.url}/${window.config.endpoints.images}`.replace(
					":id",
					taskId
				);
			const response = await fetch(url, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: bearer,
				},
				body: JSON.stringify(body),
			});
			if (!response.ok) {
				throw new Error("Failed to update task");
			}
			iziToast.success({
				title: "Update successful",
				message: "Images saved correctly",
			});
			onClose();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Error saving selected images",
			});
		}
	};

	const filteredImages = images.filter((image) =>
		image.name && image.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<ChakraProvider>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent
					sx={{
						maxWidth: "80vw",
						width: "80vw",
						maxHeight: "40vw",
						height: "40vw",
					}}
				>
					<ModalHeader>
						<Flex
							justifyContent="space-between"
							alignItems="center"
						>
							<Text>
								Selected images ({selectedImages.length} out of{" "}
								{filteredImages.length})
							</Text>
							<Flex>
								<Button
									onClick={handleSelectAll}
									colorScheme="teal"
									variant="solid"
									mr={2}
								>
									{filteredImages.every((image) =>
										selectedImages.includes(image.id)
									)
										? "Unselect all"
										: "Select all"}
								</Button>
								<Button
									onClick={handleInvertSelection}
									colorScheme="orange"
									variant="solid"
									mr={2}
								>
									Invert selection
								</Button>
								<Button
									onClick={onSaveSelectedImages}
									colorScheme="teal"
									variant="solid"
									mr={8}
								>
									Save
								</Button>
							</Flex>
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Input
							placeholder="Search images"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							mb={4}
						/>
						<SimpleGrid columns={[2, null, 4]} spacing={4}>
							{currentImages.map((image) => (
								<Box
									key={image.id}
									cursor="pointer"
									borderWidth={1}
									borderRadius="lg"
									overflow="hidden"
									onClick={() =>
										toggleImageSelection(image.id)
									}
								>
									<Box p="2">
										<Checkbox
											onChange={() =>
												toggleImageSelection(image.id)
											}
											isChecked={selectedImages.includes(
												image.id
											)}
										>
											{image.name}
										</Checkbox>
									</Box>
								</Box>
							))}
						</SimpleGrid>
					</ModalBody>
					<ModalFooter>
						<Flex w="100%" justifyContent="center">
							{filteredImages.length > 0 && (
								<PaginationComponent
									items={filteredImages}
									itemsPerPage={itemsPerPage}
									onChangePageCallback={onChangePageCallback}
								/>
							)}
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</ChakraProvider>
	);
});

export default ImagesTaskComponent;
