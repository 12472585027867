import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Handle, Position, useReactFlow } from "reactflow";
import { Icon } from "@chakra-ui/react";
import { AiFillFileImage, AiFillDelete, AiFillPicture } from "react-icons/ai";
import { Tooltip, IconButton } from "@chakra-ui/react";

import ProcessedImagesTaskComponent from "../../../tasks/ProcessedImagesTask";

import "./outputNode.css";

import * as Globals from "../../../../utils/globals";
import * as Utils from "../../../../utils/utils";

const OutputNodeComponent = React.memo(({ id, data }) => {
	const { setNodes, setEdges } = useReactFlow();
	const onDeleteNode = useCallback(() => {
		setNodes((nodes) => nodes.filter((node) => node.id !== id));
		setEdges((edges) => edges.filter((edge) => edge.source !== id));
	}, [id, setNodes, setEdges]);

	const { projectId, taskId } = useParams();
	const [selectedProjectId, setSelectedProjectId] = useState(projectId);
	const [selectedTask, setSelectedTask] = useState(null);

	// Images selection
	const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
	const [images, setImages] = useState([]);
	const bucketName = window.config.s3.bucketSubida;
	const folderPath = `${selectedProjectId}/${taskId}/${Globals.OUTPUT_IMAGES_DIRECTORY}`;

	const fetchTask = async () => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.task}`.replace(
					":id",
					taskId
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to get task data");
			}
			const responseData = await response.json();
			setSelectedTask(responseData.taskData);
			setSelectedProjectId(responseData.taskData.id_project);
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		try {
			fetchTask();
		} catch (error) {
			console.error(error);
		}
	}, [taskId]);

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const images = await Utils.listFilesInS3Bucket(
					bucketName,
					folderPath
				);
				setImages(images);
			} catch (error) {
				iziToast.error({
					title: "Error",
					message: "Error getting uploaded images",
				});
				setImages([]);
			}
		};

		if (isImagesModalOpen) {
			fetchImages();
		}
	}, [isImagesModalOpen]);

	return (
		<>
			<div className="output-image-node">
				<header className="output-image-node-header">
					<h1 className="output-image-node-header-text">
						{data.label}
					</h1>
				</header>
				<div className="output-image-node-body">
					<Icon as={AiFillFileImage} boxSize="2rem" />
					<Handle
						position={Position.Left}
						type="target"
						isConnectableStart={false}
					/>
				</div>
				<div className="output-image-node-options">
					<Tooltip label="View results">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillPicture />}
							onClick={() => setIsImagesModalOpen(true)}
							isDisabled={
								!selectedTask ||
								selectedTask.status !== Globals.TASK_STATUS.completed
							}
						/>
					</Tooltip>
					<Tooltip label="Delete">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillDelete />}
							onClick={() => onDeleteNode()}
						/>
					</Tooltip>
				</div>
			</div>
			<ProcessedImagesTaskComponent
				taskId={taskId}
				isOpen={isImagesModalOpen}
				onClose={() => setIsImagesModalOpen(false)}
				images={images}
			/>
		</>
	);
});

export default OutputNodeComponent;
