import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import {
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Input,
	Button,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { EmailIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import FooterComponent from "../home/Footer";
import HeaderComponent from "../home/Header";
import * as Globals from "../../utils/globals";

import "./login.css";

const LoginComponent = React.memo(() => {
	const navigate = useNavigate();

	//Login attributes
	const [showPassword, setShow] = React.useState(true);
	const handlePasswordViewClick = () => setShow(!showPassword);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	//Flag to display header
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const onLogin = async (event) => {
		event.preventDefault();

		try {
			const authUrl = `${window.config.loginBackend.url}/${window.config.endpoints.authentication}`;
			const params = {
				email: email,
				password: password,
			};
			const authResponse = await fetch(authUrl, {
				method: "POST",
				body: new URLSearchParams(params),
				headers: {
					"Content-Type":
						"application/x-www-form-urlencoded;charset=UTF-8",
				},
			});
			let success = authResponse.status === 200;
			if (!success) {
				throw new Error(
					`Error connecting to auth service. Response status: ${authResponse.status}`
				);
			}
			const jsonAuthResponse = await authResponse.json();
			const loginUrl = `${window.config.loginBackend.url}/${window.config.endpoints.login}`;
			const bearer = `Bearer ${jsonAuthResponse.token}`;
			const loginResponse = await fetch(loginUrl, {
				headers: {
					Authorization: bearer,
				},
			});

			success = loginResponse.status === 200;
			if (!success) {
				throw new Error(
					`Error connecting to login service. Response status: ${loginResponse.status}`
				);
			}
			iziToast.success({
				title: "Login successful",
				message: "Welcome to Obliquo AI platform",
			});

			localStorage.setItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN,
				jsonAuthResponse.token
			);
			const jsonLoginResponse = await loginResponse.json();
			localStorage.setItem(
				Globals.LOCAL_STORAGE_USER_ID,
				jsonLoginResponse.id
			);

			setIsLoggedIn(true);
			setEmail("");
			setPassword("");
		} catch (error) {
			console.error(error);
			localStorage.removeItem(Globals.LOCAL_STORAGE_BEARER_TOKEN);
			localStorage.removeItem(Globals.LOCAL_STORAGE_USER_ID);
			iziToast.error({
				title: "Error login",
				message: "Error login. Please check the user and password",
			});
		}
	};

	useEffect(() => {
		if (isLoggedIn) {
			navigate(Globals.DASHBOARD_ROUTE);
		}
	}, [isLoggedIn, navigate]);

	return (
		<>
			<HeaderComponent></HeaderComponent>
			<div className="login-app-container">
				<h1 className="login-header">Obliquo AI platform</h1>
				<main className="login-main">
					<form className="login-form" onSubmit={onLogin}>
						<ChakraProvider>
							<InputGroup>
								<InputLeftElement pointerEvents="none">
									<EmailIcon color="gray.300" />
								</InputLeftElement>
								<Input
									type="email"
									placeholder="Email"
									width="400px"
									className="login-input"
									isRequired
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</InputGroup>
							<InputGroup>
								<Input
									type={showPassword ? "password" : "text"}
									placeholder="Password"
									width="400px"
									className="login-input"
									isRequired
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
								<InputRightElement>
									<IconButton
										icon={
											showPassword ? (
												<ViewIcon />
											) : (
												<ViewOffIcon />
											)
										}
										onClick={handlePasswordViewClick}
									/>
								</InputRightElement>
							</InputGroup>
						</ChakraProvider>
						<Button type="submit" className="login-button">
							Login
						</Button>
					</form>
				</main>
			</div>
			<FooterComponent></FooterComponent>
		</>
	);
});

export default LoginComponent;
