import React from "react";
import {
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

import "./outputItem.css";

const OutputItemComponent = React.memo(() => {
	const onDragStart = (event, item) => {
		const itemString = JSON.stringify(item);
		event.dataTransfer.setData("application/reactflow", itemString);
		event.dataTransfer.effectAllowed = "move";
	};

	const items = [
		{
			className: "output-item-node",
			type: "outputImage",
			label: "Output images",
			color: "#ab8946",
		}
	];

	return (
		<AccordionItem>
			<h2>
				<AccordionButton>
					<Box as="span" flex="1" textAlign="left">
						Output
					</Box>
					<AccordionIcon />
				</AccordionButton>
			</h2>
			<AccordionPanel pb={4}>
				{items.map((item, index) => (
					<div
						key={index}
						className={item.className}
						onDragStart={(event) => onDragStart(event, item)}
						draggable
						style={{ backgroundColor: item.color }} // Agregar color de fondo
					>
						{item.label}
					</div>
				))}
			</AccordionPanel>
		</AccordionItem>
	);
});

export default OutputItemComponent;
