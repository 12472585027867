import React from "react";
import { Accordion } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";

import InputItemComponent from "./InputItem";
import OutputItemComponent from "./OutputItem";
import AnonymizeItemComponent from "./ProcessingItem";

import "./controlsSidebar.css";

const ControlsSideBarComponent = React.memo(() => {
	return (
		<ChakraProvider>
			<Accordion className="controls-sidebar-node-container">
				<InputItemComponent />
				<AnonymizeItemComponent />
				<OutputItemComponent />
			</Accordion>
		</ChakraProvider>
	);
});

export default ControlsSideBarComponent;
