import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box, Flex, Text } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { AiFillFileImage, AiFillProject } from "react-icons/ai";

import * as Globals from "../../utils/globals";

import "./projectCard.css";

const ProjectCard = React.memo(
	({ id, name, description, onUpdateProjectClick, onDeleteProjectClick }) => {
		const navigate = useNavigate();
		const onNavigateImagesProject = (id) => {
			navigate(Globals.IMAGES_ROUTE.replace(":projectId", id));
		};
		const onNavigateProjectTasks = (id) => {
			navigate(Globals.TASKS_ROUTE.replace(":projectId", id));
		};

		return (
			<Box
				className="projects-custom-card"
				borderWidth="1px"
				borderRadius="lg"
				overflow="hidden"
			>
				<Flex
					p="2"
					align="center"
					direction="column"
					justify="space-between"
					className="projects-custom-card-data"
				>
					<Text>{name}</Text>
					<Text>{description}</Text>
					<Flex width="100%" justify="space-between">
						<Tooltip label="Images">
							<IconButton
								variant="outline"
								colorScheme="blue"
								aria-label="Images"
								icon={<AiFillFileImage />}
								onClick={() => onNavigateImagesProject(id)}
							/>
						</Tooltip>
						<Tooltip label="Tasks">
							<IconButton
								variant="outline"
								colorScheme="blue"
								aria-label="Tasks"
								icon={<AiFillProject />}
								onClick={() => onNavigateProjectTasks(id)}
							/>
						</Tooltip>
						<Tooltip label="Edit">
							<IconButton
								variant="outline"
								colorScheme="blue"
								aria-label="Edit"
								icon={<EditIcon />}
								onClick={() => onUpdateProjectClick(id)}
							/>
						</Tooltip>
						<Tooltip label="Delete">
							<IconButton
								variant="outline"
								colorScheme="blue"
								aria-label="Delete"
								icon={<DeleteIcon />}
								onClick={() => onDeleteProjectClick(id)}
							/>
						</Tooltip>
					</Flex>
				</Flex>
			</Box>
		);
	}
);

export default ProjectCard;
