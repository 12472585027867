// src/main.jsx
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LoginComponent from "../login/Login";
import HomeComponent from "../home/Home.jsx";

import * as Globals from "../../utils/globals";

import "./index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Navigate to={Globals.LOGIN_ROUTE} />} />
			<Route path={Globals.LOGIN_ROUTE} element={<LoginComponent />} />
			<Route
				path={Globals.DASHBOARD_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.DASHBOARD_OPTION} />
				}
			/>
			<Route
				path={Globals.PROJECTS_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.PROJECTS_OPTION} />
				}
			/>
			<Route
				path={Globals.IMAGES_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.IMAGES_OPTION} />
				}
			/>
			<Route
				path={Globals.TASKS_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.TASKS_OPTION} />
				}
			/>
			<Route
				path={Globals.USERS_TASKS_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.TASKS_OPTION} />
				}
			/>
			<Route
				path={Globals.WORKFLOW_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.WORKFLOW_OPTION} />
				}
			/>
			<Route
				path={Globals.USERS_TASKS_WORKFLOW_ROUTE}
				element={
					<HomeComponent selectedOption={Globals.WORKFLOW_OPTION} />
				}
			/>
		</Routes>
	</BrowserRouter>
);
