import React, { useState } from "react";
import { Box, VStack, Link, Icon } from "@chakra-ui/react";
import { AiFillFund, AiFillDashboard, AiFillProject } from "react-icons/ai";
import { ChakraProvider } from "@chakra-ui/react";

import * as Globals from "../../utils/globals";

const SidebarComponent = React.memo(() => {
	const [activeLink, setActiveLink] = useState("");
	const userId = localStorage.getItem(Globals.LOCAL_STORAGE_USER_ID);

	return (
		<ChakraProvider>
			<Box
				bg="gray.800"
				color="white"
				w="250px"
				p="5"
				transition="width 0.2s"
			>
				<VStack align="start" spacing={5}>
					<Link
						href={Globals.DASHBOARD_ROUTE}
						style={{
							textDecoration:
								activeLink === Globals.DASHBOARD_OPTION
									? "underline"
									: "none",
						}}
						onClick={() => {
							setActiveLink(Globals.DASHBOARD_OPTION);
						}}
					>
						<Box display="flex" alignItems="center">
							<Icon as={AiFillDashboard} mr="3" />
							Dashboard
						</Box>
					</Link>
					<Link
						href={Globals.PROJECTS_ROUTE}
						style={{
							textDecoration:
								activeLink === Globals.PROJECTS_OPTION
									? "underline"
									: "none",
						}}
						onClick={() => setActiveLink(Globals.PROJECTS_OPTION)}
					>
						<Box display="flex" alignItems="center">
							<Icon as={AiFillFund} mr="3" />
							Projects
						</Box>
					</Link>
					<Link
						href={Globals.USERS_TASKS_ROUTE.replace(
							":userId",
							userId
						)}
						style={{
							textDecoration:
								activeLink === Globals.TASKS_OPTION
									? "underline"
									: "none",
						}}
						onClick={() => setActiveLink(Globals.TASKS_OPTION)}
					>
						<Box display="flex" alignItems="center">
							<Icon as={AiFillProject} mr="3" />
							Tasks
						</Box>
					</Link>
				</VStack>
			</Box>
		</ChakraProvider>
	);
});

export default SidebarComponent;
