import React, { useState } from "react";
import { Box, Tooltip, IconButton } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { FiUpload, FiImage } from "react-icons/fi"; // Iconos de react-icons

import BreadcrumbComponent from "../utils/Breadcrumb";
import UploadImagesComponent from "./UploadImages";
import UploadedImagesComponent from "./UploadedImages";

import "./images-home.css";

const ImagesHomeComponent = React.memo(() => {
	const [showUpload, setShowUpload] = useState(true);

	const toggleComponent = () => {
		setShowUpload((prevState) => !prevState);
	};

	return (
		<div>
			<ChakraProvider>
				<Box
					className="images-header"
					display="flex"
					alignItems="center"
				>
					<BreadcrumbComponent />
					<Tooltip
						label={
							showUpload
								? "View uploaded images"
								: "Upload images"
						}
						aria-label="Toggle between upload and view images"
					>
						<IconButton
							onClick={toggleComponent}
							ml="auto"
							icon={showUpload ? <FiImage /> : <FiUpload />}
							variant="solid"
							bg="blue.500"
							color="white"
							_hover={{ bg: "blue.600" }}
							aria-label="Toggle view"
						/>
					</Tooltip>
				</Box>
				{showUpload ? (
					<UploadImagesComponent />
				) : (
					<UploadedImagesComponent />
				)}
			</ChakraProvider>
			<div style={{ height: "50px" }}></div>
		</div>
	);
});

export default ImagesHomeComponent;
