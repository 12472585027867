import React, { useState, useEffect } from "react";
import {
	Box,
	Text,
	Grid,
	GridItem,
	Center,
	Icon,
	IconButton,
} from "@chakra-ui/react";
import {
	AiOutlineLoading3Quarters,
	AiOutlineCheckCircle,
	AiFillFund,
	AiFillPicture,
	AiOutlineReload,
} from "react-icons/ai";
import { ChakraProvider } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";
import * as Utils from "../../utils/utils";

const DashboardComponent = React.memo(() => {
	const [dashboard, setDashboard] = useState({
		totalProjects: 0,
		totalImages: 0,
		totalRunningTasks: 0,
		totalCompletedTasks: 0,
	});

	//Get data
	const fetchCountImages = async (projectIds) => {
		let totalImages = 0;
		try {
			const s3 = new AWS.S3();
			const bucket = window.config.s3.bucketSubida;
			for (const projectId of projectIds) {
				const prefix = `${projectId}/${Globals.IMAGES_DIRECTORY}`;
				try {
					const listParams = {
						Bucket: bucket,
						Prefix: prefix,
					};
					const listedObjects = await s3
						.listObjectsV2(listParams)
						.promise();
					totalImages += listedObjects.Contents.length;
				} catch (error) {
					console.error("Error listing files:", error);
					iziToast.error({
						title: "Error",
						message: "Failed to list existing files.",
					});
					return 0;
				}
			}
		} catch (error) {
			console.error(`Error fetching images data: ${error}`);
			return totalImages;
		} finally {
			return totalImages;
		}
	};

	const fetchDashboard = async () => {
		try {
			const userId = localStorage.getItem(Globals.LOCAL_STORAGE_USER_ID);
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.backend.url}/${userId}/${window.config.endpoints.dashboard}`;
			const bearer = `Bearer ${token}`;

			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error(
					"Network error. Please contact with the support team"
				);
			}
			const dashboardData = await response.json();
			const totalImages = await fetchCountImages(
				dashboardData.projectData.map((a) => a.project_id)
			);
			setDashboard({
				totalProjects: dashboardData.projectData.length,
				totalImages: totalImages,
				totalRunningTasks: dashboardData.taskData.filter(
					(a) => a.status === Globals.TASK_STATUS.running
				).length,
				totalCompletedTasks: dashboardData.taskData.filter(
					(a) => a.status === Globals.TASK_STATUS.completed
				).length,
			});
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Dashboard",
				message:
					"Error getting dashboard data. Please contact with the support team",
			});
		}
	};

	//Fetch dashboard when the component is mounted in DOM
	useEffect(() => {
		async function init() {
			await Utils.fetchCredentials();
			fetchDashboard();
		}
		init();
	}, []);

	// Render component
	return (
		<ChakraProvider>
			<Box p={5}>
				{/* Refresh button */}
				<Box display="flex" justifyContent="flex-end" mb={4}>
					<IconButton
						aria-label="Refresh Data"
						icon={<AiOutlineReload />}
						onClick={fetchDashboard}
						colorScheme="teal"
						title="Refresh"
					/>
				</Box>

				{/* Dashboard data */}
				<Grid templateColumns="repeat(3, 1fr)" gap={6}>
					<GridItem>
						<Center
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							p={4}
							flexDirection="column"
							bg="purple.50"
						>
							<Icon
								as={AiFillFund}
								w={10}
								h={10}
								color="purple.500"
							/>
							<Text mt={2} fontSize="xl">
								Projects
							</Text>
							<Text mt={2} fontSize="2xl" fontWeight="bold">
								{dashboard.totalProjects}
							</Text>
						</Center>
					</GridItem>
					<GridItem>
						<Center
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							p={4}
							flexDirection="column"
							bg="purple.50"
						>
							<Icon
								as={AiFillPicture}
								w={10}
								h={10}
								color="purple.500"
							/>
							<Text mt={2} fontSize="xl">
								Images
							</Text>
							<Text mt={2} fontSize="2xl" fontWeight="bold">
								{dashboard.totalImages}
							</Text>
						</Center>
					</GridItem>
					<GridItem>
						<Center
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							p={4}
							flexDirection="column"
							bg="blue.50"
						>
							<Icon
								as={AiOutlineLoading3Quarters}
								w={10}
								h={10}
								color="blue.500"
							/>
							<Text mt={2} fontSize="xl">
								Running Tasks
							</Text>
							<Text mt={2} fontSize="2xl" fontWeight="bold">
								{dashboard.totalRunningTasks}
							</Text>
						</Center>
					</GridItem>
					<GridItem>
						<Center
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							p={4}
							flexDirection="column"
							bg="green.50"
						>
							<Icon
								as={AiOutlineCheckCircle}
								w={10}
								h={10}
								color="green.500"
							/>
							<Text mt={2} fontSize="xl">
								Completed Tasks
							</Text>
							<Text mt={2} fontSize="2xl" fontWeight="bold">
								{dashboard.totalCompletedTasks}
							</Text>
						</Center>
					</GridItem>
				</Grid>
			</Box>
		</ChakraProvider>
	);
});

export default DashboardComponent;
