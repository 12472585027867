import React, { useState, useEffect } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Select,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";

const NewTaskComponent = React.memo(({ onAddTaskCallback }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef(null);
	const finalRef = React.useRef(null);

	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [projects, setProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState("");

	const { projectId, userId } = useParams();

	//Get projects
	const fetchProjects = async () => {
		try {
			const userId = localStorage.getItem(Globals.LOCAL_STORAGE_USER_ID);
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.backend.url}/${userId}/${window.config.endpoints.projects}`;
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error(
					"Network error. Please contact with the support team"
				);
			}
			const responseJson = await response.json();
			setProjects(responseJson.projectsData);
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Projects",
				message:
					"Error getting projects. Please contact with the support team",
			});
		}
	};

	// Se ejecuta al montar el componente
	useEffect(() => {
		if (userId) {
			fetchProjects();
		}
		if (projectId) {
			setSelectedProject(projectId);
		}
	}, []);

	const addTask = async () => {
		try {
			const body = {
				name: name,
				description: description,
				projectId: selectedProject,
			};
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.backend.url}/${window.config.endpoints.tasks}`;
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: bearer,
				},
				body: JSON.stringify(body),
			});
			if (!response.ok) {
				throw new Error("Failed to add task");
			}
			iziToast.success({
				title: "Add successful",
				message: "Task added correctly",
			});
			setName("");
			setDescription("");
			onAddTaskCallback();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message: "Failed to add task",
			});
		} finally {
			onClose();
		}
	};

	return (
		<>
			<Button onClick={onOpen} colorScheme="teal" variant="solid">
				New task
			</Button>
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create a new task</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Name</FormLabel>
							<Input
								placeholder="Name"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
									setId(e.target.value);
								}}
							/>
						</FormControl>

						<FormControl mt={4}>
							<FormLabel>Description</FormLabel>
							<Input
								placeholder="Description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</FormControl>

						{userId && (
							<FormControl mt={4}>
								<FormLabel>Project</FormLabel>
								<Select
									placeholder="Select project"
									value={selectedProject}
									onChange={(e) =>
										setSelectedProject(e.target.value)
									}
								>
									{projects.map((project) => (
										<option
											key={project.id}
											value={project.id}
										>
											{project.name}
										</option>
									))}
								</Select>
							</FormControl>
						)}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={addTask}>
							Save
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

export default NewTaskComponent;
