import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Handle, Position, useReactFlow } from "reactflow";
import { Icon } from "@chakra-ui/react";
import { AiFillFileImage, AiFillDelete, AiFillPicture  } from "react-icons/ai";
import { Tooltip, IconButton } from "@chakra-ui/react";

import ImagesTaskComponent from "../../../tasks/ImagesTask";

import "./inputNode.css";

import * as Globals from "../../../../utils/globals";
import * as Utils from "../../../../utils/utils";

const InputNodeComponent = React.memo(({ id, data }) => {
	const { projectId, taskId } = useParams();
	const [selectedProjectId, setSelectedProjectId] = useState(projectId);

	// Images selection
	const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
	const [images, setImages] = useState([]);
	const bucketName = window.config.s3.bucketSubida;
	const folderPath = `${selectedProjectId}/${Globals.IMAGES_DIRECTORY}`;
	
	const fetchTask = async() => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url =
				`${window.config.backend.url}/${window.config.endpoints.task}`.replace(
					":id",
					taskId
				);
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to get task data");
			}
			const responseData = await response.json();
			setSelectedProjectId(responseData.taskData.id_project)
		} catch (error) {
			throw error
		}
	} 

	useEffect(() => {
		try {
			fetchTask();
		} catch (error) {
			console.error(error)
		}
	})

	useEffect(() => {
		const fetchImages = async () => {
			try {
				const files = await Utils.listFilesInS3Bucket(
					bucketName,
					folderPath
				);
				setImages(files);
			} catch (error) {
				iziToast.error({
					title: "Error",
					message: "Error getting uploaded files",
				});
				setImages([]);
			}
		};

		if (isImagesModalOpen) {
			fetchImages();
		}
	}, [isImagesModalOpen]);

	const { setNodes, setEdges } = useReactFlow();
	const onDeleteNode = useCallback(() => {
		setNodes((nodes) => nodes.filter((node) => node.id !== id));
		setEdges((edges) => edges.filter((edge) => edge.source !== id));
	}, [id, setNodes, setEdges]);

	return (
		<>
			<div className="input-image-node">
				<header className="input-image-node-header">
					<h1 className="input-image-node-header-text">
						{data.label}
					</h1>
				</header>
				<div className="input-image-node-body">
					<Icon as={AiFillFileImage} boxSize="2rem" />
					<Handle position={Position.Right} type="source" />
				</div>
				<div className="input-image-node-options">
					<Tooltip label="Select images">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillPicture  />}
							onClick={() => setIsImagesModalOpen(true)}
						/>
					</Tooltip>
					<Tooltip label="Delete">
						<IconButton
							variant="outline"
							colorScheme="black"
							boxSize="0.8rem"
							ml={2}
							icon={<AiFillDelete />}
							onClick={() => onDeleteNode()}
						/>
					</Tooltip>
				</div>
			</div>
			<ImagesTaskComponent
				isOpen={isImagesModalOpen}
				onClose={() => setIsImagesModalOpen(false)}
				images={images}
			/>
		</>
	);
});

export default InputNodeComponent;
