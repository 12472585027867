import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
} from "@chakra-ui/react";
import { FormLabel, Button, Input } from "@chakra-ui/react";
import iziToast from "izitoast";

import * as Globals from "../../utils/globals";

import "izitoast/dist/css/iziToast.min.css";

const UpdateProjectComponent = React.memo(
	({ isOpen, currentProjectData, onCloseModal, onUpdateProjectCallback }) => {
		if (!currentProjectData.id) {
			//To avoid display the modal with empty values the first time it is opened
			return;
		}
		const [name, setName] = useState(currentProjectData.name);
		const [description, setDescription] = useState(
			currentProjectData.description
		);

		const onUpdateProject = async () => {
			try {
				const updatedProject = {
					name: name,
					description: description,
				};
				const token = localStorage.getItem(
					Globals.LOCAL_STORAGE_BEARER_TOKEN
				);
				const url =
					`${window.config.backend.url}/${window.config.endpoints.project}`.replace(
						":id",
						currentProjectData.id
					);
				const bearer = `Bearer ${token}`;
				const response = await fetch(url, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: bearer,
					},
					body: JSON.stringify(updatedProject),
				});
				if (!response.ok) {
					throw new Error("Failed to update project");
				}
				iziToast.success({
					title: "Update successful",
					message: "Project updated correctly",
				});
				onUpdateProjectCallback(updatedProject);
			} catch (error) {
				console.error(error);
				iziToast.error({
					title: "Update project",
					message:
						"Error updating project. Please contact the support team",
				});
			} finally {
				onCloseModal();
			}
		};

		return (
			<ChakraProvider>
				<Modal isOpen={isOpen} onClose={onCloseModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Update project</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
								<FormLabel>Name</FormLabel>
								<Input
									isRequired
									placeholder="Name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</FormControl>

							<FormControl mt={4}>
								<FormLabel>Description</FormLabel>
								<Input
									placeholder="Description"
									value={description}
									onChange={(e) =>
										setDescription(e.target.value)
									}
								/>
							</FormControl>
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme="blue"
								mr={3}
								onClick={onUpdateProject}
							>
								Save
							</Button>
							<Button onClick={onCloseModal}>Cancel</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</ChakraProvider>
		);
	}
);

export default UpdateProjectComponent;
