import React, { useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react";
import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";

const NewProjectComponent = React.memo(({ onAddProjectCallback }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const initialRef = React.useRef(null);
	const finalRef = React.useRef(null);

	const [id, setId] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const addProject = async () => {
		try {
			const userId = localStorage.getItem(Globals.LOCAL_STORAGE_USER_ID);
			const body = {
				userId: userId,
				name: name,
				description: description,
			};
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const url = `${window.config.backend.url}/${window.config.endpoints.projects}`;
			const bearer = `Bearer ${token}`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: bearer,
				},
				body: JSON.stringify(body),
			});
			if (!response.ok) {
				throw new Error("Failed to add project");
			}
			iziToast.success({
				title: "Add successful",
				message: "Project added correctly",
			});
			setName("");
			setDescription("");
			onAddProjectCallback();
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Error",
				message:
					"Error adding project. Please contact the support team",
			});
		} finally {
			onClose();
		}
	};

	return (
		<>
			<Button onClick={onOpen} colorScheme="teal" variant="solid">
				New project
			</Button>
			<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create a new project</ModalHeader>
					<ModalCloseButton />
					<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Name</FormLabel>
							<Input
								placeholder="Name"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
									setId(e.target.value);
								}}
							/>
						</FormControl>
						<FormControl mt={4}>
							<FormLabel>Description</FormLabel>
							<Input
								placeholder="Description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={addProject}>
							Save
						</Button>
						<Button onClick={onClose}>Cancel</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

export default NewProjectComponent;
