import React from "react";
import { useParams } from "react-router-dom";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";

const DeleteTaskComponent = React.memo(
	({ isOpen, idTask, onCloseDialog, onDeleteTaskCallback }) => {
		const { projectId } = useParams();

		const deleteTaskImages = async () => {
			const s3 = new AWS.S3();
			const bucket = window.config.s3.bucketSubida;
			const prefix = `${projectId}/${idTask}/`;
			try {
				const listParams = {
					Bucket: bucket,
					Prefix: prefix,
				};
				const listedObjects = await s3
					.listObjectsV2(listParams)
					.promise();

				if (listedObjects.Contents.length > 0) {
					const deleteParams = {
						Bucket: bucket,
						Delete: {
							Objects: listedObjects.Contents.map(({ Key }) => ({
								Key,
							})),
							Quiet: true,
						},
					};
					await s3.deleteObjects(deleteParams).promise();
				}
			} catch (error) {
				console.error("Error deleting files:", error);
				iziToast.error({
					title: "Error",
					message: "Failed to delete existing files.",
				});
				throw error;
			}
		};

		const onDeleteTask = async () => {
			try {
				await deleteTaskImages();
				const token = localStorage.getItem(
					Globals.LOCAL_STORAGE_BEARER_TOKEN
				);
				const url =
					`${window.config.backend.url}/${window.config.endpoints.task}`.replace(
						":id",
						idTask
					);
				const bearer = `Bearer ${token}`;
				const response = await fetch(url, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: bearer,
					},
				});
				if (!response.ok) {
					throw new Error("Failed to delete task");
				}
				iziToast.success({
					title: "Delete successful",
					message: "Task deleted correctly",
				});
				if (onDeleteTaskCallback) {
					onDeleteTaskCallback();
				}
			} catch (error) {
				console.error(error);
				iziToast.error({
					title: "Delete task",
					message: "Error deleting task",
				});
			} finally {
				onCloseDialog();
			}
		};

		return (
			<AlertDialog isOpen={isOpen} onClose={onCloseDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete task
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button onClick={onCloseDialog}>Cancel</Button>
							<Button
								colorScheme="red"
								onClick={onDeleteTask}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		);
	}
);

export default DeleteTaskComponent;
