import React, { useState, useRef, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ReactFlow, {
	ReactFlowProvider,
	addEdge,
	useNodesState,
	useEdgesState,
	getIncomers,
	getOutgoers,
	getConnectedEdges,
	Controls,
} from "reactflow";
import { Box, Button } from "@chakra-ui/react";

import ControlsSideBarComponent from "./sidebar/ControlsSidebar";
import InputNodeComponent from "./nodes/input/InputNode";
import OutputNodeComponent from "./nodes/output/OutputNode";
import AnonymizeNodeComponent from "./nodes/processing/AnonymizeNode";
import BreadcrumbComponent from "../utils/Breadcrumb";

import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

import * as Globals from "../../utils/globals";

import "reactflow/dist/style.css";
import "./workflow.css";

let defaultId = 0;
const getId = (nodeId) => `dndnode_${++nodeId}`;

const nodeTypes = {
	inputImage: InputNodeComponent,
	outputImage: OutputNodeComponent,
	hideFaces: AnonymizeNodeComponent,
};

const WorkflowHomeComponent = React.memo(() => {
	const reactFlowWrapper = useRef(null);
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);

	const { taskId } = useParams();

	useEffect(() => {
		loadWorkflow();
	}, []);

	const canNodesConnect = (sourceNode, targetNode) => {
		return (
			(sourceNode.type === "inputImage" &&
				targetNode.type === "hideFaces") ||
			(sourceNode.type === "hideFaces" &&
				targetNode.type === "outputImage")
		);
	};

	const onConnect = useCallback(
		(newEdge) => {
			const sourceNode = nodes.find((node) => node.id === newEdge.source);
			const targetNode = nodes.find((node) => node.id === newEdge.target);
			if (canNodesConnect(sourceNode, targetNode)) {
				newEdge.type = "step";
				setEdges((oldEdges) => addEdge(newEdge, oldEdges));
			}
		},
		[nodes, setEdges]
	);

	const onNodesDelete = useCallback(
		(deleted) => {
			setEdges(
				deleted.reduce((acc, node) => {
					const incomers = getIncomers(node, nodes, edges);
					const outgoers = getOutgoers(node, nodes, edges);
					const connectedEdges = getConnectedEdges([node], edges);
					const remainingEdges = acc.filter(
						(edge) => !connectedEdges.includes(edge)
					);
					const createdEdges = incomers.flatMap(({ id: source }) =>
						outgoers.map(({ id: target }) => ({
							id: `${source}->${target}`,
							source,
							target,
						}))
					);
					const modifiedEdges = createdEdges.map((edge) => {
						edge.animated = true;
						return edge;
					});

					return [...remainingEdges, ...modifiedEdges];
				}, edges)
			);
		},
		[nodes, edges]
	);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onDrop = useCallback(
		(event) => {
			event.preventDefault();
			const nodeItem = JSON.parse(
				event.dataTransfer.getData("application/reactflow")
			);

			if (!nodeItem) {
				return;
			}

			// Verificar si ya existe un nodo de este tipo
			const nodeExists = nodes.find(
				(node) => node.type === nodeItem.type
			);

			if (nodeExists) {
				iziToast.error({
					title: "Duplicated element",
					message: "The element you are trying to add already exists",
				});
				return;
			}

			const position = reactFlowInstance.screenToFlowPosition({
				x: event.clientX,
				y: event.clientY,
			});
			//Find max node id
			const maxId = nodes
				.map((node) => parseInt(node.id.replace("dndnode_", ""), 10))
				.reduce(
					(max, current) => (current > max ? current : max),
					defaultId
				);

			const newNode = {
				id: getId(maxId),
				type: nodeItem.type,
				position,
				data: { label: nodeItem.label, color: nodeItem.color },
			};

			setNodes((nds) => nds.concat(newNode));
		},
		[reactFlowInstance, nodes, setNodes]
	);

	const onSaveWorkflow = async () => {
		try {
			const body = {
				nodes,
				edges,
			};
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const bearer = `Bearer ${token}`;
			const url =
				`${window.config.backend.url}/${window.config.endpoints.workflow}`.replace(
					":id",
					taskId
				);
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: bearer,
				},
				body: JSON.stringify(body),
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			iziToast.success({
				title: "Workflow",
				message: "Workflow saved successfully",
			});
		} catch (error) {
			console.error(error);
			iziToast.error({
				title: "Workflow",
				message: "Error saving workflow",
			});
		}
	};

	const loadWorkflow = async () => {
		try {
			const token = localStorage.getItem(
				Globals.LOCAL_STORAGE_BEARER_TOKEN
			);
			const bearer = `Bearer ${token}`;
			const url =
				`${window.config.backend.url}/${window.config.endpoints.workflow}`.replace(
					":id",
					taskId
				);
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: bearer,
				},
			});
			if (!response.ok) {
				throw new Error(
					"Network error. Please contact with the support team"
				);
			}
			const responseJson = await response.json();
			const workflowData = responseJson.workflowData;
			if (workflowData) {
				const { nodes, edges } = workflowData.metadata;
				setNodes(nodes);
				setEdges(edges);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<ChakraProvider>
				<Box className="workflow-header">
					<BreadcrumbComponent />
					<Button
						onClick={onSaveWorkflow}
						colorScheme="teal"
						variant="solid"
					>
						Save
					</Button>
				</Box>
			</ChakraProvider>

			<div className="dnd-flow">
				<ControlsSideBarComponent />
				<ReactFlowProvider>
					<div className="reactflow-wrapper" ref={reactFlowWrapper}>
						<ReactFlow
							nodes={nodes}
							edges={edges}
							nodeTypes={nodeTypes}
							onNodesChange={onNodesChange}
							onEdgesChange={onEdgesChange}
							onNodesDelete={onNodesDelete}
							onConnect={onConnect}
							onInit={setReactFlowInstance}
							onDrop={onDrop}
							onDragOver={onDragOver}
							fitView
						>
							<Controls />
						</ReactFlow>
					</div>
				</ReactFlowProvider>
			</div>
		</>
	);
});

export default WorkflowHomeComponent;
