export const LOCAL_STORAGE_BEARER_TOKEN = "bearerToken";
export const LOCAL_STORAGE_USER_ID = "userId";

//Routes
export const LOGIN_ROUTE = "/login";
export const DASHBOARD_ROUTE = "/dashboard";
export const PROJECTS_ROUTE = "/projects";
export const IMAGES_ROUTE = "/projects/:projectId/images";
export const TASKS_ROUTE = "/projects/:projectId/tasks";
export const USERS_TASKS_ROUTE = "/users/:userId/tasks";
export const WORKFLOW_ROUTE = "/projects/:projectId/tasks/:taskId/workflow";
export const USERS_TASKS_WORKFLOW_ROUTE = "/users/:userId/tasks/:taskId/workflow";

//Sidebar options
export const DASHBOARD_OPTION = "Dashboard";
export const PROJECTS_OPTION = "Projects";
export const IMAGES_OPTION = "Images";
export const TASKS_OPTION = "Tasks";
export const WORKFLOW_OPTION = "Workflow";

export const IMAGES_DIRECTORY = "images";
export const OUTPUT_IMAGES_DIRECTORY = "output";

//Task status
export const TASK_STATUS = {
      loading: "loading",
      running: "running",
      completed: "completed",
      failed: "failed"
}